import React, { useEffect, useState } from 'react';
import { Layout } from '../../components/Layout/Layout';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { getSummaryRequest } from './api';
import { getCellColor, getFormattedCellDate } from './utils';
import { STAGES, STAGES_BY_ORDER, STAGES_TEXTS } from './constants';
import { TSummaryItem } from './types';
import { useReportingVersion } from '../../hooks/useReportingVersion';
import { SHeader1 } from '../styles';
import { REPORT_NAME } from '../ProposalReporting/constants';
import { getLinkClassByVersion } from '../ProposalReporting/utils/getLinkClassByVersion';
import { TProposalReportingVersion } from '../ProposalReporting/types';

const DEFAULT_VERSION = '3';

const ReportLink = ({ version }: { version: TProposalReportingVersion }) => {
  const { version: currentVersion, setVersion } = useReportingVersion();

  return (
    <SReportLink
      className={getLinkClassByVersion(version, currentVersion || DEFAULT_VERSION)}
      onClick={() => {
        setVersion(version);
      }}
    >
      {REPORT_NAME[version]}
    </SReportLink>
  );
};

export const SummaryPage = () => {
  const [summaryData, setSummaryData] = useState<TSummaryItem[]>([]);
  const { version } = useReportingVersion();

  useEffect(() => {
    getSummaryRequest(version || DEFAULT_VERSION).then((summaryData: TSummaryItem[]) => {
      setSummaryData(summaryData);
      // setSummaryData(summaryRespMock);
      // setSummaryData(summaryRespMockLite);
      // setSummaryData(summaryRespMockFailed);
    });
  }, [version]);

  return (
    <Layout>
      <SHeader1>
        Summary
        <SHeader1Hint>
          <ReportLink version={'1'} />
          <ReportLink version={'2'} />
          <ReportLink version={'3'} />
        </SHeader1Hint>
      </SHeader1>
      {summaryData.length > 0 && (
        <STable>
          <thead>
            <tr>
              <STh>PI Name</STh>
              {STAGES_BY_ORDER.map((stageName: STAGES) => (
                <STdScore key={stageName}>{STAGES_TEXTS[stageName]}</STdScore>
              ))}
            </tr>
          </thead>
          <tbody>
            {summaryData.map((dataItem, i) => (
              <tr key={i}>
                <STd textAlign="left">
                  <Link
                    to={`/applications/${dataItem.id}/reporting?version=${version || DEFAULT_VERSION}`}
                    className="link-alt-1"
                  >
                    #{dataItem.id}. {`${dataItem.firstName} ${dataItem.lastName}`}
                  </Link>
                  <span className="hint">{dataItem.institution}</span>
                </STd>
                {STAGES_BY_ORDER.map((stage, i) => {
                  return (
                    <STd style={{ width: '15%', backgroundColor: getCellColor(dataItem.report, stage) }} key={i}>
                      {getFormattedCellDate(dataItem.report, stage)}
                    </STd>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </STable>
      )}
    </Layout>
  );
};

const colors = {
  voted: '#dffbe0',
  conflict: '#f6f6f6',
};

const SHeader1Hint = styled.div`
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  margin-left: 16px;
  text-transform: none;
`;

const STable = styled.table`
  width: 100%;
  margin-bottom: 1rem;
  font-size: 14px;
  color: #212529;
  vertical-align: top;
  border: 1px solid #e6e6e6;
  border-collapse: collapse;
`;

const STd = styled.td<{ textAlign?: 'right' | 'left' }>`
  padding: 0.5rem 0.5rem;
  border: 1px solid #e6e6e6;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'center')};
`;

const STh = styled(STd)`
  padding: 0.5rem 0.5rem;
  border: 1px solid #e6e6e6;
  font-weight: bold;
  background-color: #f3f3f3;
`;

const STdScore = styled(STh)`
  width: 7%;
`;

const SReportLink = styled.span`
  margin-right: 12px;
  cursor: pointer;
`;
