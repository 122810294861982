import { allEditableFinancialReportItems } from '../../../constants/financialReportItems';
import {
  IFinancialExpenditureReport,
  IFinancialReportStructure,
  IPersonnelReport,
  IProposalReporting,
  IScientificProgressReport,
} from '../types';

const validateScientific = (report: IScientificProgressReport) => {
  return !!report.reportFile;
};

const validateFinancial = (report: IFinancialExpenditureReport) => {
  if (!report?.data) return false;

  return allEditableFinancialReportItems.every((key: keyof IFinancialReportStructure) => {
    const val = report.data[key].budgetExpenses;
    return val !== undefined && val !== null;
  });
};

const validateAccounting = (report) => {
  return !!report.reportFile;
};

const validateInvoice = (report: IPersonnelReport) => {
  return Boolean(report.reportFile);
};

const validatePersonnel = (report: IPersonnelReport) => {
  return (
    report.data.budgetSupported !== undefined &&
    report.data.budgetSupported !== null &&
    report.data.subAwardBudgetSupported !== undefined &&
    report.data.subAwardBudgetSupported !== null
  );
};

export const validateProposalReporting = (proposalReporting: IProposalReporting, version): string => {
  if (!validateScientific(proposalReporting.scientificProgressReport)) {
    return 'Please attach a PDF-file in the Scientific Progress Report.';
  }

  if (!validateFinancial(proposalReporting.financialExpenditureReport)) {
    return 'Please fill in all required fields in the Financial Expenditure Report.';
  }

  if (!validatePersonnel(proposalReporting.personnelReport)) {
    return 'Please fill in all required fields in the Personnel Report.';
  }

  if (!validateInvoice(proposalReporting.personnelReport)) {
    return 'Please attach a PDF-file in the Invoice section.';
  }

  if (version === '2') {
    if (!validateAccounting(proposalReporting.accountingReport)) {
      return 'Please attach a PDF-file in the Accounting Report.';
    }
  }

  return '';
};
