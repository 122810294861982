import React from 'react';
import { ReportFile } from '../../components/components/ReportFile/ReportFile';
import { TApplicationId, TChangeGlobalStateCb, TProposalReportingVersion, TReportFile } from '../../types';
import styled from 'styled-components';

type TProps = {
  reportFile: TReportFile;
  changeGlobalState: TChangeGlobalStateCb;
  disabled: boolean;
  applicationId: TApplicationId;
  isSubmitted: boolean;
  version: TProposalReportingVersion;
};
export const ScientificProgressReport = ({
  version,
  reportFile,
  changeGlobalState,
  disabled = false,
  applicationId,
  isSubmitted,
}: TProps) => {
  return (
    <>
      <div style={{ marginBottom: 18 }}>
        <ReportFile
          required
          reportFile={reportFile}
          changeGlobalState={changeGlobalState}
          section="scientificProgressReport"
          placeholder="Click to upload PDF file. 3-page limit"
          disabled={disabled}
          applicationId={applicationId}
          isSubmitted={isSubmitted}
        />
      </div>

      <ul className="hint-list">
        <li>3 pages.</li>
        {(version === '2' || version === '3') && (
          <SListItemBold>
            Summarize the work (half page) completed in&nbsp;the previous reporting period(s) and then provide
            a&nbsp;detailed description for the current reporting period.
          </SListItemBold>
        )}
        {version === '1' && (
          <SListItemBold>Summary of&nbsp;the progress made during the reporting period.</SListItemBold>
        )}
        {version === '2' && (
          <SListItemBold>
            For the 1-year report, a&nbsp;full 12-month scientific report is&nbsp;required only if&nbsp;the direction
            of&nbsp;the project in&nbsp;different from what was proposed/accomplished in&nbsp;the first (6-month)
            report.
          </SListItemBold>
        )}
        {version === '3' && (
          <SListItemBold>
            For the 2-year report, a&nbsp;full 12-month scientific report is&nbsp;required only if&nbsp;the direction
            of&nbsp;the project in&nbsp;different from what was proposed/accomplished in&nbsp;the Year 1 report.
          </SListItemBold>
        )}
        <li>Report progress against submitted milestones and deliverables.</li>
        <li>
          If&nbsp;some or&nbsp;all of&nbsp;the periodic goals were not met, why? Were there unanticipated challenges and
          how were those challenges addressed?
        </li>
        <li>
          Anticipated progress to&nbsp;be&nbsp;made during the next reporting period, including plans for expending
          funds of&nbsp;the second installment.
        </li>
        {version === '1' && (
          <li>
            List of&nbsp;publications and manuscripts submitted and in&nbsp;press that have resulted from work performed
            during the reporting period.
          </li>
        )}
      </ul>
    </>
  );
};

const SListItemBold = styled.li`
  font-weight: bold;
`;
