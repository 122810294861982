import React, { FC, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { getReportingLink } from '../utils/getReportingLink';
import { TProposalReportingMode, TProposalReportingVersion } from '../types';
import { useReportingVersion } from '../../../hooks/useReportingVersion';
import { getLinkClassByVersion } from '../utils/getLinkClassByVersion';
import { REPORT_NAME } from '../constants';
import styled from 'styled-components';

type TSwitcherProps = {
  mode: TProposalReportingMode;
  id: number | string;
};

type TLinkProps = TSwitcherProps & {
  version: TProposalReportingVersion;
};

const ReportingLink: FC<TLinkProps> = ({ version, mode, id }) => {
  const { version: currentVersion } = useReportingVersion();

  return (
    <Link
      to={getReportingLink(mode, version, id)}
      className={getLinkClassByVersion(version, currentVersion)}
      // style={version === '1' ? { margin: '0 5px' } : {}}
    >
      {REPORT_NAME[version]}
    </Link>
  );
};

const Delimiter = () => <SDelimiter>|</SDelimiter>;

export const ReportVersionsSwitcher: FC<TSwitcherProps> = ({ mode, id }) => {
  return (
    <div style={{ fontSize: 14, fontWeight: 'bold' }}>
      REPORTS:{' '}
      {(['1', '2', '3'] as const).map((version, index, arr) => {
        const isLast = index === arr.length - 1;
        return (
          <Fragment key={version}>
            <ReportingLink version={version} mode={mode} id={id} />
            {!isLast && <Delimiter />}
          </Fragment>
        );
      })}
    </div>
  );
};

const SDelimiter = styled.span`
  margin: 0 10px;
`;
