export const MENU_LINKS_VERSION_1 = {
  'scientific-report': 'Scientific Progress Report',
  'milestones-report': 'Milestones and Deliverables',
  'financial-report': 'Financial Expenditure Report',
  'personnel-report': 'Personnel Report',
  'invoice-report': 'Invoice',
};

export const MENU_LINKS_VERSION_2 = {
  'scientific-report': 'Scientific Progress Report',
  'publications-and-abstracts': 'Publications and Abstracts',
  'milestones-report': 'Milestones and Deliverables',
  'financial-report': 'Financial Expenditure Report',
  'accounting-report': 'Accounting Report',
  'personnel-report': 'Personnel Report',
  'invoice-report': 'Invoice',
};
