import React from 'react';
import { ReportFile } from '../../components/components/ReportFile/ReportFile';
import { TApplicationId, TChangeGlobalStateCb, TReportFile } from '../../types';

type TProps = {
  reportFile: TReportFile;
  changeGlobalState: TChangeGlobalStateCb;
  disabled: boolean;
  applicationId: TApplicationId;
  isSubmitted: boolean;
};
export const PublicationsAndAbstracts = ({
  reportFile,
  changeGlobalState,
  disabled = false,
  applicationId,
  isSubmitted,
}: TProps) => {
  return (
    <>
      <div style={{ marginBottom: 18 }}>
        <ReportFile
          reportFile={reportFile}
          changeGlobalState={changeGlobalState}
          section="publicationsAndAbstracts"
          placeholder="Click to upload PDF file. No page limit"
          disabled={disabled}
          applicationId={applicationId}
          isSubmitted={isSubmitted}
        />
      </div>

      <ul className="hint-list">
        <li>
          List publications, manuscripts in press, and manuscripts submitted that have resulted from work performed
          during the reporting period.
        </li>
        <li>List oral presentations, poster presentations, and conference abstracts during the reporting period.</li>
      </ul>
    </>
  );
};
