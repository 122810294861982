import React, { useState } from 'react';
import { Layout } from '../../components/Layout/Layout';
import { FinancialExpenditureReport } from './parts/FinancialExpenditure/FinancialExpenditureReport';
import { ScientificProgressReport } from './parts/SciencificProgress/ScientificProgressReport';
import { MilestonesDeliverables } from './parts/MilestonesDeliverables/MilestonesDeliverables';
import { PersonnelReport } from './parts/PersonnelReport/PersonnelReport';
import { SHeader1, SHeader1Subheader } from '../styles';
import { Button } from '../../components/Button/Button';
import { ProposalReportComments } from './components/components/ProposalReportComments/ProposalReportComments';
import styled from 'styled-components';
import {
  canSeeAndWriteReportingComments,
  canSeeNewsfeed,
  canSeeSummaryInfoInProposalReporting,
  canSubmitReporting,
} from '../../utils/accesses';
import format from 'date-fns/format';
import { parseISO } from 'date-fns';
import { TProposalReportingMode } from './types';
import { SendBackForm } from './components/SendBackForm/SendBackForm';
import { useEnhanced } from './hooks/useEnhanced';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { PseudoLink } from '../../components/PseudoLink';
import { SubHeader } from '../../components/Header/SubHeader';
import { SummaryInfo } from './components/SummaryInfo';
import { SubmittedInfo } from './components/SubmittedInfo';
import { ReportVersionsSwitcher } from './components/ReportVersionsSwitcher';
import { PublicationsAndAbstracts } from './parts/PublicationsAndAbstracts/PublicationsAndAbstracts';
import { Accounting } from './parts/Accounting/Accounting';
import { NavigationFakeDiv } from './components/NavigationFakeDiv';
import { ReportFile } from './components/components/ReportFile/ReportFile';
import { Link, useParams } from 'react-router-dom';
import { SNav, SNavPrev } from '../GrantsRequest/features/Nav/styles';
import { MENU_LINKS_VERSION_1, MENU_LINKS_VERSION_2 } from './components/SubHeader/constants/menuLinks';
import { SLeft, SRight } from '../../components/Header/styles';
import { getApplicationLink } from './utils/getApplicationLink';
import { REPORT_NAME } from './constants';
import { getReportingLink } from './utils/getReportingLink';

export const ProposalReportingPage = ({ mode }: { mode?: TProposalReportingMode }) => {
  const {
    proposalReporting,
    setProposalReporting,
    dict,
    state,
    onSubmit,
    getReportingId,
    changeGlobalState,
    setSandBackState,
    sendBackState,
    onSendBack,
    print,
    onRemoveComment,
    canSendBack,
    submitButtonText,
    isSubmittedByPI,
    reportIdFromUrl,
    version,
  } = useEnhanced(mode);

  const [showSendBackForm, setShowSendBackForm] = useState(false);
  const appId = useParams().id;

  if (!proposalReporting) {
    return null;
  }

  const commonProps = {
    changeGlobalState: changeGlobalState,
    disabled: !state.canEditForm,
    applicationId: getReportingId(),
    isSubmitted: isSubmittedByPI,
  };

  const getPrintableLink = (type: string) => {
    return getReportingLink(undefined, version, getReportingId(), { print: type });
  };

  const MENU_LINKS = ['2', '3'].includes(version) ? MENU_LINKS_VERSION_2 : MENU_LINKS_VERSION_1;

  return (
    <Layout
      loading={false}
      subHeader={
        <SubHeader
          firstLine={
            <>
              <SLeft>
                <Link to={getApplicationLink(mode, appId)}>
                  <SNavPrev>Application</SNavPrev>
                </Link>
              </SLeft>
              <SRight>
                <ReportVersionsSwitcher mode={mode} id={getReportingId()} />
              </SRight>
            </>
          }
          secondLine={
            <>
              <SLeft>
                <div>
                  {Object.keys(MENU_LINKS).map((linkDataKey) => (
                    <SNav href={`#${linkDataKey}`}>{MENU_LINKS[linkDataKey]}</SNav>
                  ))}
                </div>
                <SHeader1AwardeeName>
                  {`Awardee ${proposalReporting.principalInvestigator.firstName} ${proposalReporting.principalInvestigator.lastName}`}
                  <div style={{ fontSize: 15 }}>{REPORT_NAME[version]} Report</div>
                </SHeader1AwardeeName>
              </SLeft>
              <SRight>
                {canSubmitReporting(dict.user.userType, proposalReporting.reportLevels) && (
                  <Button
                    type="submit"
                    value={submitButtonText}
                    disabled={state.isSubmitting}
                    style={{ padding: '2px 10px', whiteSpace: 'nowrap' }}
                    onClick={(e) => {
                      e.preventDefault();
                      onSubmit(dict.user.userType);
                      document.getElementById('block-buttons').scrollIntoView();
                    }}
                  />
                )}
              </SRight>
            </>
          }
        />
      }
    >
      <br />

      <SNoPrintable>
        {canSeeSummaryInfoInProposalReporting(dict.user.userType) ? (
          <SummaryInfo reportIdFromUrl={reportIdFromUrl} />
        ) : (
          <SubmittedInfo reportSubmittedByPI={proposalReporting.reportLevels.reportSubmittedByPI} />
        )}

        <br />
        <div style={{ position: 'relative' }}>
          <NavigationFakeDiv id={'scientific-report'} />
          <SHeader1>Scientific Progress Report</SHeader1>
          <ScientificProgressReport
            reportFile={proposalReporting.scientificProgressReport.reportFile}
            version={version}
            {...commonProps}
          />
        </div>
        <br />
        <br />
      </SNoPrintable>

      {['2', '3'].includes(version) && (
        <SNoPrintable>
          <div style={{ position: 'relative' }}>
            <NavigationFakeDiv id={'publications-and-abstracts'} />
            <SHeader1>Publications and Abstracts</SHeader1>
            <PublicationsAndAbstracts
              reportFile={proposalReporting.publicationsAndAbstracts.reportFile}
              {...commonProps}
            />
          </div>
          <br />
          <br />
        </SNoPrintable>
      )}

      <SPrintable isPrintOnePartOfPageMode={!!print} shouldBePrintable={print === 'milestones'}>
        <div style={{ position: 'relative' }}>
          <NavigationFakeDiv id={'milestones-report'} />

          <SHeader1>
            Milestones and Deliverables
            {version === '1' && (
              <span style={{ fontSize: 16, fontWeight: 'normal', textTransform: 'lowercase', marginLeft: 10 }}>
                As submitted with proposal
              </span>
            )}
            <SNoPrintable style={{ display: 'inline-block', marginLeft: 18 }}>
              <a href={getPrintableLink('milestones')} target="_blank">
                <FontAwesomeIcon icon={faPrint} color="grey" />
              </a>
            </SNoPrintable>
          </SHeader1>
          <MilestonesDeliverables
            data={proposalReporting.milestonesAndDeliverable.data}
            forPrint={print === 'milestones'}
            {...commonProps}
          />
        </div>
      </SPrintable>
      <br />
      <br />
      <SPrintable isPrintOnePartOfPageMode={!!print} shouldBePrintable={print === 'financial'}>
        <div style={{ position: 'relative' }}>
          <NavigationFakeDiv id={'financial-report'} />

          <SHeader1 withSubheader>
            Financial Expenditure Report
            <SNoPrintable style={{ display: 'inline-block', marginLeft: 18 }}>
              <a href={getPrintableLink('financial')} target="_blank">
                <FontAwesomeIcon icon={faPrint} color="grey" />
              </a>
            </SNoPrintable>
          </SHeader1>
          {version === '3' && <SHeader1Subheader>NOTE: For Years 1 and 2 of the award</SHeader1Subheader>}
          <FinancialExpenditureReport
            data={proposalReporting.financialExpenditureReport.data}
            reportFile={proposalReporting.financialExpenditureReport.reportFile}
            forPrint={print === 'financial'}
            version={version}
            {...commonProps}
          />
        </div>
      </SPrintable>
      <br />
      <br />

      {['2', '3'].includes(version) && (
        <SNoPrintable>
          <div style={{ position: 'relative' }}>
            <NavigationFakeDiv id={'accounting-report'} />
            <SHeader1>Accounting Report</SHeader1>
            <Accounting reportFile={proposalReporting.accountingReport.reportFile} {...commonProps} />
          </div>
          <br />
          <br />
        </SNoPrintable>
      )}

      <SPrintable isPrintOnePartOfPageMode={!!print} shouldBePrintable={print === 'personnel'}>
        <div style={{ position: 'relative' }}>
          <NavigationFakeDiv id={'personnel-report'} />

          <SHeader1>
            Personnel Report
            <SNoPrintable style={{ display: 'inline-block', marginLeft: 18 }}>
              <a href={getPrintableLink('personnel')} target="_blank">
                <FontAwesomeIcon icon={faPrint} color="grey" />
              </a>
            </SNoPrintable>
          </SHeader1>

          <PersonnelReport
            data={proposalReporting.personnelReport.data}
            forPrint={print === 'personnel'}
            {...commonProps}
          />

          <br />
          <br />

          {['2', '3'].includes(version) && (
            <>
              <ReportFile
                reportFile={proposalReporting.personnelReportExplained.reportFile}
                section="personnelReportExplained"
                placeholder="Click to upload PDF file. 3-page limit"
                {...commonProps}
              />
              <br />
              <br />
            </>
          )}

          <ul className="hint-list">
            <li>
              If the number of personnel supported on this award is considerably more or less than the approved budget,
              provide an appropriate explanation for this difference.
            </li>
          </ul>
        </div>
      </SPrintable>
      <br />
      <br />
      <br />

      <div style={{ position: 'relative' }}>
        <NavigationFakeDiv id="invoice-report" />
        <SHeader1>Invoice</SHeader1>
        <div>
          <ReportFile
            required
            reportFile={proposalReporting.personnelReport.reportFile}
            section="personnelReport"
            placeholder="Click to upload PDF file. 3-page limit"
            {...commonProps}
          />
        </div>
      </div>

      <hr />

      <SNoPrintable>
        <SSubmitButtons>
          <NavigationFakeDiv id={'block-buttons'} />

          {state.validationError && (
            <div>
              <div
                style={{
                  display: 'inline-block',
                  padding: 10,
                  border: '1px solid #edb54f',
                  borderRadius: 5,
                  marginBottom: 10,
                }}
              >
                {state.validationError}
              </div>
            </div>
          )}
          {state.showThankYou && (
            <div>
              <div
                style={{
                  display: 'inline-block',
                  padding: 10,
                  border: '1px solid rgb(37 189 56)',
                  borderRadius: 5,
                  marginBottom: 10,
                }}
              >
                Your request has been submitted.
              </div>
            </div>
          )}
          {sendBackState.showSentBack && (
            <div>
              <div
                style={{
                  display: 'inline-block',
                  padding: 10,
                  border: '1px solid #edb54f',
                  borderRadius: 5,
                  marginBottom: 10,
                }}
              >
                The report has been sent back.
              </div>
            </div>
          )}
          <SSubmitOrSendBack>
            {canSubmitReporting(dict.user.userType, proposalReporting.reportLevels) && (
              <>
                <SSubmitButton>
                  <Button
                    type="submit"
                    value={submitButtonText}
                    disabled={state.isSubmitting}
                    onClick={(e) => {
                      e.preventDefault();
                      onSubmit(dict.user.userType);
                    }}
                  />
                </SSubmitButton>

                {canSendBack && (
                  <SSendBackWrapper>
                    or{' '}
                    <PseudoLink
                      onClick={() => {
                        setShowSendBackForm(!showSendBackForm);
                      }}
                    >
                      Send Back
                    </PseudoLink>
                  </SSendBackWrapper>
                )}

                {showSendBackForm && canSendBack && (
                  <SendBackForm
                    onSendBack={onSendBack}
                    setSandBackState={setSandBackState}
                    sandBackState={sendBackState}
                  />
                )}
              </>
            )}
          </SSubmitOrSendBack>
        </SSubmitButtons>
      </SNoPrintable>

      <SNoPrintable>
        {canSeeNewsfeed(dict.user.userType) && proposalReporting.actionsFeed.length > 0 && (
          <div style={{ margin: '20px 0 50px' }}>
            <SHeader1>Newsfeed</SHeader1>
            {proposalReporting.actionsFeed.map((feedItem) => {
              return (
                <div key={feedItem.id} style={{ marginBottom: 20, fontSize: 15 }}>
                  <div style={{ fontSize: 14, color: '#000', opacity: 0.5 }}>
                    {format(parseISO(feedItem.created_at), 'MMM dd, yyyy HH:mm')}
                  </div>
                  {feedItem.author.name}: {feedItem.action}
                </div>
              );
            })}
          </div>
        )}

        {canSeeAndWriteReportingComments(dict.user.userType) && (
          <SComments>
            <ProposalReportComments
              comments={proposalReporting.comments}
              addComment={(newComment) => {
                setProposalReporting((draft) => {
                  draft.comments.push(newComment);
                });
              }}
              reportId={getReportingId()}
              onRemove={onRemoveComment}
            />
          </SComments>
        )}
      </SNoPrintable>
      {/*{isSubmitTouched && isAnyErrorsInWholeForm(errors) && (*/}
      {/*  <STextError>*/}
      {/*    Please fix errors before isSubmitting. Either you can save your proposal and submit it later.*/}
      {/*  </STextError>*/}
      {/*)}*/}
      {/*{serverMessage && <STextError>{serverMessage}</STextError>}*/}
    </Layout>
  );
};

const SSubmitButton = styled.span`
  margin-right: 20px;
`;
const SSendBackWrapper = styled.span`
  top: 8px;
  position: relative;
  margin-right: 20px;
`;

const SSubmitOrSendBack = styled.span`
  display: flex;
  align-items: flex-start;
`;

const SComments = styled.div`
  margin-bottom: 20px;
`;

const SHeader1AwardeeName = styled(SHeader1)`
  margin-top: 20px;
  margin-bottom: 5px;
`;

const SSubmitButtons = styled.div`
  position: relative;
  margin: 30px 0 60px;
`;

const SPrintable = styled.div<{ isPrintOnePartOfPageMode: boolean; shouldBePrintable: boolean }>`
  @media print {
    display: ${({ isPrintOnePartOfPageMode, shouldBePrintable }) => {
      if (isPrintOnePartOfPageMode) {
        if (shouldBePrintable) {
          return 'block';
        } else {
          return 'none';
        }
      } else {
        return 'block';
      }
    }};
  }
`;

const SNoPrintable = styled.div`
  @media print {
    display: none;
  }
`;
