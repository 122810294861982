import React, { FC } from 'react';
import { SubmittedSteps } from './constants/submittedSteps';
import { canSeeReportingLink } from '../../utils/accesses';
import { ReportingLink } from './components/ReportingLink';
import { TMode, TProposal } from './types';
import { STAGES } from '../../constants/stages';
import { getReportingLink } from '../ProposalReporting/utils/getReportingLink';

type TProps = {
  proposal: TProposal;
  stage: STAGES;
  id: number | string;
  mode?: TMode;
};

export const SubmittedApplicationView: FC<TProps> = ({ proposal, stage, id, mode }) => {
  if (proposal === null) {
    return null;
  }

  return (
    <>
      {canSeeReportingLink(null, stage) && <ReportingLink href={getReportingLink(mode, '3', id)} />}

      {SubmittedSteps.map((step) => {
        const Component = step.component;
        const state = proposal[step.id];
        return (
          <div key={step.id} style={{ position: 'relative' }}>
            <div id={step.id} style={{ position: 'absolute', top: -162 }}></div>
            <Component state={state} title={step.name} />
          </div>
        );
      })}
    </>
  );
};
