import React, { FC } from 'react';
import { MENU_LINKS } from './SubHeader/constants/menuLinks';

type TProps = {
  id: keyof typeof MENU_LINKS | 'block-buttons';
};

export const NavigationFakeDiv: FC<TProps> = ({ id }) => {
  const header = document.getElementById('page-header');
  const headerHeight = header?.getBoundingClientRect()?.height || 0;

  return <div id={id} style={{ position: 'absolute', top: -headerHeight }} />;
};
